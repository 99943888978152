import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel, UsersQueryModel, UsersSearchQueryModel } from '../../models/user-search-list.model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ServiceConstant } from 'src/app/const/insignts-service-routing.const';
import { UserLoginHistoryModel } from '../../models/user-login-list.model';
import { HttpClientInsightsService } from 'src/app/services/http-client-insights.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private appUrl = `${environment.serviceUrl}/${ServiceConstant.USER_ADMIN_USERS}`;

  constructor(private _httpClient: HttpClientInsightsService) { }

  public getUsersList(params: UsersSearchQueryModel): Observable<UserModel[]> {
    let paramQuery = (params.email.length > 0 || params.project_name.length > 0) ? '?' : '';

    paramQuery += params.email.length > 0 ?
                `email=${params.email}` : '';

    paramQuery += params.project_name.length > 0 ?
                `&` : '';

    paramQuery += params.project_name.length > 0 ?
                `project_name=${params.project_name}` : '';

    const url = `${this.appUrl}/${ServiceConstant.USER_ADMIN_USERS_FILTERS}${paramQuery}`;
    return this._httpClient
          .get(url)
          .pipe(
            map((response: any) => {
              const userList: UserModel[] = [];
              if (response && response.results) {
                response.results.forEach((element: any, index: number) => {
                  userList.push(new UserModel({...element, index}));
                });
              }
              return userList;
            })
          );
  }

  public getUserLoginHistory(params: UserModel): Observable<UserLoginHistoryModel[]> {
    return this._httpClient
          .get(`${this.appUrl}/${ServiceConstant.USER_ADMIN_USERS_LOGIN_HISTORY}?email=${params.email}`)
          .pipe(
            map((response: any) => {
              const userLogList: UserLoginHistoryModel[] = [];
              if (response && response.results) {
                response.results.forEach((element: any) => {
                  userLogList.push(new UserLoginHistoryModel({...element}));
                });
              }
              return userLogList;
            })
          );
  }

  public createNewUser(user: UsersQueryModel): Observable<any>{
    return this._httpClient.post(`${this.appUrl}/${ServiceConstant.USER_ADMIN_ADD_USERS}`, user, 'APP.USERS.CREATE_STATUS_USER');
  }

  public updateUser(user: UsersQueryModel): Observable<any>{
    return this._httpClient.put(`${this.appUrl}/${ServiceConstant.USER_ADMIN_UPDATE_USERS}`, user, 'APP.USERS.UPDATE_STATUS_USER');
  }
}
