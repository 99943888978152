import { Injectable } from '@angular/core';
import { AdminLayoutService } from './admin-layout.service';
import { Observable } from 'rxjs';
import { UserInfoModel } from '../models/user-info.model';

@Injectable({
  providedIn: 'root'
})
export class AdminLayoutStore {
  constructor(private _adminLayoutService: AdminLayoutService) { }

  public getUserAdminStatus(): Observable<UserInfoModel> {
    return this._adminLayoutService.getUserAdminStatus();
  }
}
