import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainDashboardComponent } from './components/main-dashboard/main-dashboard.component';
import { SharedModule } from '../module-shared/shared.module';
import { TableauDashboardRoutingModule } from './tableau-dashboard-routing.module';

@NgModule({
  declarations: [
    MainDashboardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TableauDashboardRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TableauDashboardModule { }
