import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { LoginComponent } from './module-authorization/components/login/login.component';
import { oktaSettings } from './okta/okta-configuration';
import { SharedModule } from './module-shared/shared.module';
import { TableauDashboardModule } from './module-tableau-dashboard/tableau-dashboard.module';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { SpinnerService } from './module-shared/services/spinner.service';
import { AlertToastService } from './module-shared/services/alert-toast.service';
import { JwtModule } from '@auth0/angular-jwt';
import { JwtTokenManagementStore } from './module-authorization/services/jwt-token-management.store';
import { OKTA_TOKEN_STORAGE_KEY } from './const/token.const';
import { AuthOktaListenerService } from './services/auth-okta-listener.service';
import { ProjectsResolver } from './module-administrator/resolvers/projects.resolver';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return  new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  const oktaToken = localStorage.getItem(OKTA_TOKEN_STORAGE_KEY) as any;
  return oktaToken?.accessToken?.accessToken;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    OktaAuthModule,
    TableauDashboardModule,
    SharedModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
  ],
  providers: [
    BsModalService,
    TranslateService,
    SpinnerService,
    AlertToastService,
    JwtTokenManagementStore,
    AuthOktaListenerService,
    ProjectsResolver,
    {
      provide: OKTA_CONFIG,
      useFactory: () => oktaSettings()
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
