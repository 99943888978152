<div class="login">
    <div class="d-flex login--container justify-content-center">
        <div class="card login--card">
            <div class="login--header">
                <picture>
                    <source srcset="assets/logo-inline-text-light.svg" media="(min-width: 768px)" width="257px">
                    <img src='assets/logo-inline-text-light.svg' alt="Onyx centersource logo" width="200px">
                </picture>
               
                <h2 class="login--header-title">Insights to Drive Better<br>Business Outcomes</h2>
            </div>
            <div class="d-block okta-container">
                <div class="login--banner">
                    <app-alert-inline [message]="message" *ngIf="message"></app-alert-inline>
                </div>
                <div id="sign-in-widget"></div>
            </div>
        </div>
    </div>
</div>