import { HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtTokenManagementStore } from '../services/jwt-token-management.store';
import { environment } from 'src/environments/environment';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  public isUserLoggedIn = false;

  constructor(private jwtTokenManagementStore: JwtTokenManagementStore) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = this.jwtTokenManagementStore.getToken();
    if (req.url.includes(environment.serviceUrl)) {
      req = req.clone({
        url:  req.url,
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(req);
  }

}
