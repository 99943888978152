import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserModel } from '../model/user.model';
import { Router } from '@angular/router';
import { RouteConstant } from '../const/insignts-routing.const';

@Injectable({
  providedIn: 'root'
})
export class AuthOktaListenerService {

  public statusAuthSubs: BehaviorSubject<any> = new BehaviorSubject(null);
  public userAuthenticatedSubs: Subject<UserModel> = new Subject();
  public logoutSubs: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private _router: Router, ) { }

  setIsUserAuthenticated(user: boolean) {
    this.statusAuthSubs.next(user);
  }

  getIsUserAuthenticated() {
    return this.statusAuthSubs.asObservable();
  }

  setUserAuthenticated(user: UserModel) {
    this.userAuthenticatedSubs.next(user);
  }

  getUserAuthenticated() {
    return this.userAuthenticatedSubs.asObservable();
  }

  redirectToDashboard() {
    this._router.navigateByUrl(`${RouteConstant.SECURE}/${RouteConstant.DASHBOARD}`);
  }

  redirectToAdmin() {
    this._router.navigateByUrl(`${RouteConstant.SECURE}/${RouteConstant.ADMIN}`);
  }

  redirectToLogin() {
    this._router.navigateByUrl(`/`);
  }
}
