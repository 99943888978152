
export class ServiceConstant {
    static readonly DASHBOARD = 'getDashboardByUser';
    static readonly USER_ADMIN = 'getUserInformation';
    static readonly USER_ADMIN_ALL_PROJECTS = 'getListProjects';
    static readonly USER_ADMIN_DASHBOARD = 'dashboard';
    static readonly USER_ADMIN_PROJECTS = 'project';
    static readonly USER_ADMIN_USERS = 'user';
    static readonly USER_ADMIN_USERS_FILTERS = 'getUserByFilter';
    static readonly USER_ADMIN_PROJECT_USER = 'getUsersbyProject';
    static readonly USER_ADMIN_USERS_LOGIN_HISTORY = 'getLogginInfoByUser';
    static readonly USER_ADMIN_PROJECT_LIST = 'showProjects';
    static readonly USER_ADMIN_ADD_USERS = 'addNewUser';
    static readonly USER_ADMIN_UPDATE_USERS = 'updateUser';
    static readonly USER_ADMIN_UPDATE_PROJECT = 'updateProject';
    static readonly USER_ADMIN_ADD_PROJECT = 'addNewProject';
    static readonly USER_ADMIN_SEARCH_AUTOCOMPLETE_PROJECT = 'searchAutoPopulate';

}
