import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { Tokens } from '@okta/okta-auth-js';
import OktaSignIn from '@okta/okta-signin-widget';
import { LOADING_LOGIN } from 'src/app/const/loading.const';
import { SpinnerService } from 'src/app/module-shared/services/spinner.service';
import { AuthOktaListenerService } from 'src/app/services/auth-okta-listener.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  signIn: any = null;
  DEFAULT_ORIGINAL_URI = window.location.origin;
  message =  '';
  signSettings = {
    el: '#sign-in-widget',
    scopes: environment.auth.scopes
  };
  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
              public authStateService: OktaAuthStateService,
              private _authOktaListenerService: AuthOktaListenerService,
              private _spinnerService: SpinnerService) {
    this.createSigIn();
  }

  ngOnInit() {
    this._spinnerService.stopLoading(LOADING_LOGIN);
    this.signIn?.showSignInToGetTokens(this.signSettings)
      .then((tokens: Tokens) => {
        if (tokens) {
          this._spinnerService.startLoading(LOADING_LOGIN);
          this.oktaAuth.handleLoginRedirect(tokens).then(() => {
            this._authOktaListenerService.redirectToDashboard();
          });
        }
      }).catch((err: any) => {
        throw err;
      });
  }

  ngOnDestroy() {
     if (this.signIn) { this.signIn.remove(); }
  }

  private createSigIn() {
    this.signIn = new OktaSignIn({
      baseUrl: environment.auth.issuer.split('/oauth2')[0],
      clientId: environment.auth.clientId,
      redirectUri: environment.auth.redirectUri,
      language: 'en',
      logo: 'assets/logo-inline-text-light.svg',
      i18n: {
        en: {
          'primaryauth.title': 'Onyx Insights Login',
          needhelp: ' '
        }
      },
      authClient: this.oktaAuth,
      useClassicEngine: true
    });
  }
}
