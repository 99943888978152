import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertToastService } from '../module-shared/services/alert-toast.service';
import { catchError, map } from 'rxjs/operators';
import { getErrorMessage } from '../module-shared/services/errors.utils';
import { Observable, throwError } from 'rxjs';
import { AlertToastParams } from '../module-shared/models/alert-toast-params.model';
import { SpinnerService } from '../module-shared/services/spinner.service';
import { GuidService } from './guid.service';

@Injectable({
  providedIn: 'root'
})
export class HttpClientInsightsService {

  constructor(private _httpClient: HttpClient,
              private _alertToastService: AlertToastService,
              private _spinnerService: SpinnerService) { }

  public get(url: string, skipCode: number[] | null = null, activeSpinner = true): Observable<any> {
    let newGuid = '';
    if (activeSpinner) {
      newGuid = GuidService.getNewGuid();
      this._spinnerService.startLoading(newGuid);
    }
    return this._httpClient.get(url).pipe(
      map(it => {
        if (true) {
          this._spinnerService.stopLoading(newGuid);
        }
        return it;
      }),
      catchError(err => {
        const isCodeSkipped = skipCode?.includes(err.status);
        if (!isCodeSkipped) {
          this.showErrorAlert(err);
        }
        if (true) {
          this._spinnerService.stopLoading(newGuid);
        }
        return throwError(err);
      })
    );
  }

  public post(url: string, params: any, successMessage: string = ''): Observable<any> {
    const newGuid = GuidService.getNewGuid();
    this._spinnerService.startLoading(newGuid);
    return this._httpClient.post(url, params).pipe(
      map(it => {
        this._spinnerService.stopLoading(newGuid);
        if (successMessage.length > 0) {
          this._alertToastService.success({alertToastMessage: successMessage});
        }
        return it;
      }),
      catchError((err: any) => {
        this._spinnerService.stopLoading(newGuid);
        return this.showErrorAlert(err);
      })
    );
  }

  public put(url: string, params: any, successMessage: string = ''): Observable<any> {
    const newGuid = GuidService.getNewGuid();
    this._spinnerService.startLoading(newGuid);
    return this._httpClient.put(url, params).pipe(
      map(it => {
        this._spinnerService.stopLoading(newGuid);
        if (successMessage.length > 0) {
          this._alertToastService.success({alertToastMessage: successMessage});
        }
        return it;
      }),
      catchError((err: any) => {
        this._spinnerService.stopLoading(newGuid);
        return this.showErrorAlert(err);
       })
    );
  }

  private showErrorAlert(err: any) {
    const error = getErrorMessage(err);
    const alertError = new AlertToastParams({alertToastMessage: error.msg, detail: error});
    this._alertToastService.error(alertError);
    return throwError(err);
  }
}
