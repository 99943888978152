import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceConstant } from 'src/app/const/insignts-service-routing.const';
import { environment } from 'src/environments/environment';
import { UserInfoModel } from '../models/user-info.model';
import { HttpClientInsightsService } from 'src/app/services/http-client-insights.service';

@Injectable({
  providedIn: 'root'
})
export class AdminLayoutService {
  private appUrl = `${environment.serviceUrl}`;

  constructor(private _httpClient: HttpClientInsightsService) { }

  public getUserAdminStatus(): Observable<UserInfoModel> {
    return this._httpClient
          .get(`${this.appUrl}/${ServiceConstant.USER_ADMIN_USERS}/${ServiceConstant.USER_ADMIN}`)
          .pipe(
            map(response => {
              if (response) {
                return new UserInfoModel(response);
              }
              return new UserInfoModel();
            })
          );
  }
}
