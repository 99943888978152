export class ProjectItemModel {
    id = 0;
    name = '';
    status = '';

    constructor(data: any){
        if (data) {
            this.id = data.id;
            this.name = data.project_name;
            this.status = data.status;
        }
    }
}

export class ProjectAllListModel {
    list: ProjectItemModel[] = [];
    constructor(data: any = null){
        if (data) {
            this.list = this.getProjectList(data.project_list);
        }
    }

    private getProjectList(data: any = null) {
        const newList: ProjectItemModel[] = [];
        if (data) {
            for (let index = 0; index < data.length; index++) {
                newList.push(
                    new ProjectItemModel(
                        {
                            id: index + 1,
                            project_name: data[index].project_name,
                            status: data[index].status
                        }
                    )
                );
            }
        }
        return newList;
    }
}
