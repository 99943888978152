import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminLayoutListenerService {
  public userAdminSubs: BehaviorSubject<any> = new BehaviorSubject(null);
  public userActiveSubs: BehaviorSubject<any> = new BehaviorSubject(null);
  public userUrlAdminSubs: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor() {}

  setIsUserAdmin(userAdmin: boolean) {
    this.userAdminSubs.next(userAdmin);
  }

  setIsUserActive(userActive: boolean) {
    this.userActiveSubs.next(userActive);
  }

  getIsUserAdmin() {
    return this.userAdminSubs.asObservable();
  }

  getIsUserActive() {
    return this.userActiveSubs.asObservable();
  }

  setIsUrlAdmin(userAdmin: boolean) {
    this.userUrlAdminSubs.next(userAdmin);
  }

  getIsUrlAdmin() {
    return this.userUrlAdminSubs.asObservable();
  }
}
