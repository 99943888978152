import { CodeError } from '../const/code-error';
import { DetailErrorModel } from '../models/alert-toast.model';


export const getErrorMessage = (err: any) => {
    const { error, status} = err;
    const details = (status === CodeError.ERROR_500) ? get500Error(error) : `${error.detail || ''}`;
    const errorDefaultMessage = 'MESSAGE.VALIDATION.REQUEST_ERROR_DEFAULT';
    let errorTranslate = (status === CodeError.ERROR_500) ?
        'MESSAGE.VALIDATION.REQUEST_ERROR_500' :
        'MESSAGE.VALIDATION.REQUEST_ERROR_400';
    const detail = (status === CodeError.ERROR_500);

    if (status !== CodeError.ERROR_500 &&
        status !== CodeError.ERROR_400) {
        errorTranslate = errorDefaultMessage;
    }

    return new DetailErrorModel({
        msg: errorTranslate,
        errorDetail: details,
        isDetail: detail
    });
};

const get500Error = (error: any) => {
    if (error.detail && error.detail.details) {
        return error.detail.details;
    }
    else if (error.detail && !error.detail.details) {
        return error.detail;
    }
};
