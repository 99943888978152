import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProjectsService } from '../services/projects/projects.service';
import { Observable } from 'rxjs/internal/Observable';
import { ProjectAllListModel } from '../models/project-list.model';
import { ProjectStore } from '../services/projects/projects.store';

@Injectable()
export class ProjectsResolver implements Resolve<any> {

  constructor(private ProjectsStore: ProjectStore) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProjectAllListModel> {
    return this.ProjectsStore.loadAllProjectList();
  }
}
