import { PROJECT_STATUS } from '../const/project-list.const';

export class ProjectSearchQueryModel {
    project_name = '';
    active_status = '';

    constructor(data: any = null){
        if (data) {
            this.active_status = data.status || '';
            this.project_name = data.projectName || '';
        }
    }
}

export class ProjectQueryModel {
    old_project_name = '';
    new_project_name = '';
    url = '';
    active_flag = '';
    main_flag = PROJECT_STATUS.active.toString();

    constructor(data: any = null){
        if (data) {
            this.old_project_name = data.oldProjectName;
            this.new_project_name = data.projectName;
            this.url = data.url;
            this.active_flag = data.isActive ?
                            PROJECT_STATUS.active.toString() :
                            PROJECT_STATUS.inactive.toString();
        }
    }
}

export class ProjectModel {
    uniqId: string | null = null;
    projectName = '';
    url = '';
    oldProjectName = '';
    isActive = false;
    createdTime = '';
    createdBy = '';
    updatedTime = '';
    userCount = 0;

    constructor(data: any = null){
        if (data) {
            this.uniqId = data.index + '_active';
            this.projectName = data.project_name;
            this.oldProjectName = data.project_name;
            this.isActive = data.status === PROJECT_STATUS.active.toString();
            this.createdTime = data.created_time || '';
            this.createdBy = data.created_by || '';
            this.updatedTime = data.updated_time || '';
            this.userCount = data.user_count || 0;
            this.url = data.url || '';
        }
    }
}
