import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ProjectAllListModel } from '../../models/project-list.model';
import { map, take } from 'rxjs/operators';
import { ProjectsService } from './projects.service';
import { PROJECT_STATUS } from '../../const/project-list.const';
import { ProjectModel, ProjectQueryModel, ProjectSearchQueryModel } from '../../models/project-search-list.model';
import { UserModel, UsersSearchQueryModel } from '../../models/user-search-list.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectStore {

  private projectListObs$: BehaviorSubject<ProjectAllListModel | null> = new BehaviorSubject<ProjectAllListModel | null>(null);

  constructor(private _projectsService: ProjectsService) {}

  public loadAllProjectList(): Observable<ProjectAllListModel>{
    return this.getAllProjectList()
              .pipe(take(1),
                    map(data => {
                      this.projectListObs$.next(data);
                      return data;
                    })
                  );
  }

  public getProjectsObs() {
    return this.projectListObs$;
  }

  public getActiveProjectsObs() {
    return this.projectListObs$.pipe(map(item => item?.list.filter(data => data.status === 'active')));
  }

  public getProjectList(params: ProjectSearchQueryModel): Observable<ProjectModel[]> {
    return this._projectsService.getProjectList(params);
  }

  public getUserByProject(params: UsersSearchQueryModel): Observable<UserModel[]>{
    return this._projectsService.getUserByProject(params);
  }

  public createNewProject(params: ProjectQueryModel) {
    return this._projectsService.createNewProject(params);
  }

  public updateProject(params: ProjectQueryModel) {
    return this._projectsService.updateProject(params);
  }

  public getAutoSearchProject(params: string) {
    return this._projectsService.getAutoSearchProject(params);
  }
  private getAllProjectList(): Observable<ProjectAllListModel> {
    return this._projectsService.getAllProjectList();
  }
}
