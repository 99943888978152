export class DashboardModel {
    status = '';
    URL = '';

    constructor(data: any = null)
    {
      if (data != null) {
        this.status = data.status;
        this.URL = data.URL;
      }
    }
  }
