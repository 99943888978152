import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConstant } from '../const/insignts-routing.const';
import { MainDashboardComponent } from './components/main-dashboard/main-dashboard.component';

export const PENDING_MATCHES_ROUTES: Routes = [
    {
      path: RouteConstant.APP_CONST_EMPTY,
      component: MainDashboardComponent
    }
  ];

@NgModule({
    imports: [RouterModule.forChild(PENDING_MATCHES_ROUTES)],
    exports: [RouterModule]
  })
  export class TableauDashboardRoutingModule { }
