import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { AuthOktaListenerService } from './services/auth-okta-listener.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from './module-shared/services/spinner.service';
import { JwtTokenManagementStore } from './module-authorization/services/jwt-token-management.store';
import { LOADING_NAVIGATION, LOADING_START_APP, LOADING_USER_INFO } from './const/loading.const';
import { AdminLayoutListenerService } from './module-authorization/services/admin-layout-listener.service';
import { RouteConstant } from './const/insignts-routing.const';
import { AdminLayoutStore } from './module-authorization/services/admin-layout.store';
import { catchError, take } from 'rxjs/operators';
import { UserInfoModel } from './module-authorization/models/user-info.model';
import { throwError } from 'rxjs';
import moment from 'moment';
import OktaAuth from '@okta/okta-auth-js';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit{
  public title = 'onyx-insights-web';
  public isLoading = false;
  public isUserAdmin = false;
  public isUserActive = false;
  public isLogged: boolean | null = null;
  public currentUrl = '';
  public currentUserInfo: UserInfoModel | null = null;

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
              private _router: Router,
              private _translate: TranslateService,
              private _authOktaListenerService: AuthOktaListenerService,
              private _spinnerService: SpinnerService,
              private _jwtTokenManagementStore: JwtTokenManagementStore,
              private _adminLayoutListenerService: AdminLayoutListenerService,
              private _adminLayoutStore: AdminLayoutStore) {
    this._translate.setDefaultLang('en');
    this._translate.use('en');
    this._spinnerService.startLoading(LOADING_START_APP);
    this.validateLocalStorage();
    this.createSubscriptions();
  }

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this._spinnerService.stopLoading(LOADING_START_APP);
  }

  private createSubscriptions() {
    this._router.events.subscribe((routerEvent: any) => {
      if (routerEvent instanceof NavigationStart) {
        this.currentUrl = routerEvent.url;
        this._spinnerService.startLoading(LOADING_NAVIGATION);
        try {
          this.oktaAuth.session.exists().then((exists: boolean) => {
            if (exists) {
              const existToken = this._jwtTokenManagementStore.getToken();
              if (!existToken) {
                this._jwtTokenManagementStore.removeSession();
                this._authOktaListenerService.redirectToLogin();
              }
              this.getUserInfo();
            }
            if (routerEvent.url === '/') {
              if (exists) { this._authOktaListenerService.redirectToDashboard(); }
              else { this._authOktaListenerService.redirectToLogin(); }
            }
          });
        }
        catch (err: any) {}
      }
      if (routerEvent instanceof NavigationEnd ||
          routerEvent instanceof NavigationCancel ||
          routerEvent instanceof NavigationError) {
          setTimeout(() => {
            this._spinnerService.stopLoading(LOADING_NAVIGATION);
          }, 2000);
      }
    });

    this.oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
    });

    this._spinnerService.getLoadingStatus().subscribe(loadingArr => {
      this.isLoading = loadingArr.length > 0;
    });
  }

  private validateLocalStorage() {
    this.getExpirationTime();
    this.oktaAuth.isAuthenticated().then(status => {
      if (!status) {
        this._spinnerService.stopAllLoading();
      }
    });
  }

  private getExpirationTime() {
    const dateNow = new Date();
    const tokenExpDate = this._jwtTokenManagementStore.getTokenExpirationDate();
    if (tokenExpDate) {
      const tokenTimeToExpire =  Math.floor(moment(tokenExpDate).diff(dateNow));
      const realExptTime = (Math.max(Math.floor(tokenTimeToExpire / 1000)));

      if (realExptTime && realExptTime < environment.tokenExpiredTimeToClose) {
        this._jwtTokenManagementStore.removeSession();
        this._spinnerService.stopAllLoading();
      }
    }
  }

  private validateAdminUrl(url: string) {
    const urlSpt = url.split('/');
    const isUrlAdmin = (urlSpt.includes(RouteConstant.ADMIN) && urlSpt[2] === RouteConstant.ADMIN);
    this._adminLayoutListenerService.setIsUrlAdmin(isUrlAdmin && this.isUserAdmin);
    if (isUrlAdmin && !this.isUserAdmin) {
      this._authOktaListenerService.redirectToDashboard();
    }
  }

  private getUserInfo() {
    if (!this.currentUserInfo) {
      this._spinnerService.startLoading(LOADING_USER_INFO);
      this._adminLayoutStore.getUserAdminStatus()
          .pipe(
            take(1),
            catchError(err => {
              this.currentUserInfo = new UserInfoModel();
              this.isUserAdmin = false;
              this.isUserActive = false;
              this._adminLayoutListenerService.setIsUserAdmin(this.isUserAdmin);
              this._adminLayoutListenerService.setIsUserActive(this.isUserActive);

              this._spinnerService.stopLoading(LOADING_USER_INFO);
              this._authOktaListenerService.redirectToDashboard();
              return throwError(err);
            })
          ).subscribe(user => {
          if (user) {
            this.currentUserInfo = user;
            this.isUserAdmin = user.isUserAdmin;
            this.isUserActive = user.isUserActive;
            this._adminLayoutListenerService.setIsUserAdmin(this.isUserAdmin);
            this._adminLayoutListenerService.setIsUserActive(this.isUserActive);

            if (!user.isUserActive) {
              this._spinnerService.stopLoading(LOADING_USER_INFO);
              this._authOktaListenerService.redirectToDashboard();
            }
            else {
              this.validateAdminUrl(this.currentUrl);
              this._spinnerService.stopLoading(LOADING_USER_INFO);
            }
          }
        });
    } else {
      this.validateAdminUrl(this.currentUrl);
    }
  }
}
