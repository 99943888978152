import { EnvConf } from 'src/app/model/env-config.model';

export const environment: EnvConf = {
  name: 'prod',
  serviceUrl: 'https://insights.service.onyxcentersource.com/api',
  production: true,
  auth: {
    clientId: '0oai398wycpxhi2vi4x7',
    issuer: 'https://onyxcentersource.okta.com/oauth2/ausi39nca44HiWtpC4x7',
    redirectUri: 'https://insights.onyxcentersource.com/login/callback',
    scopes: ['openid', 'profile', 'email', 'tableau:views:embed', 'offline_access'],
    grandType: 'authorization_code',
    tokenManager: {
      autoRenew: true
    }
  },
  appName: 'Onyx Insights',
  version: '0.0.1',
  tokenExpiredTimeToClose: -2400,
  alert: {
    alertSuccessTimeout: 3000,
    alertErrorTimeout: 0,
    alertWarningTimeout: 0,
    alertInfoTimeout: 2000
  }
};
