import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertToastComponent } from './components/alert-toast/alert-toast.component';
import { AlertInlineComponent } from './components/alert-inline/alert-inline.component';
import { AlertConfirmationComponent } from './components/alert-confirmation/alert-confirmation.component';
import { NgxDesignSystemComponentsModule } from '@onyx/ngx-design-system-components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RefreshTokenInterceptor } from '../module-authorization/interceptors/refresh-token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TopNavigationComponent } from './components/top-navigation/top-navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { TableEmptyResultComponent } from './components/table-empty-result/table-empty-result.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';



@NgModule({
  declarations: [
    AlertToastComponent,
    AlertInlineComponent,
    AlertConfirmationComponent,
    SpinnerComponent,
    TopNavigationComponent,
    FooterComponent,
    TableEmptyResultComponent,
    PaginationComponent,
    AccessDeniedComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxDesignSystemComponentsModule,
    NgSelectModule,
    FormsModule
  ],
  exports: [
    NgxDesignSystemComponentsModule,
    AlertToastComponent,
    AlertInlineComponent,
    AlertConfirmationComponent,
    TranslateModule,
    SpinnerComponent,
    TopNavigationComponent,
    FooterComponent,
    TableEmptyResultComponent,
    PaginationComponent,
    AccessDeniedComponent
  ],
  providers: [
    TranslateService,
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true }

  ]
})
export class SharedModule { }
