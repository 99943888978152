<div class="alert-fixed pioneer-ods alert-toast-container" *ngIf="messages && messages.length > 0">
    <div class="position-relative" *ngFor='let message of messages;let i = index'>
        <div class="alert-toast alert-dismissible" role="alert" [ngClass]="message.cssClass" *ngIf="!message.removed">
            <i class="alert-icon far" aria-hidden="true" [ngClass]="message.icoCss"></i>
            <div aria-live="polite" role="alertdialog" class="alert-toast-message col-12 pr-5 row" *ngIf="message.message != ''">
                <ng-container>
                    <div style="white-space: pre-wrap">
                       <span *ngIf="!message.detail" id="simple-error"> 
                            {{message.message}}
                       </span>
                       <span *ngIf="message.detail && !message.detail.isDetail" id="simple-error-detail"> 
                            {{message.message}} {{message?.detail?.errorDetail || ''}}
                       </span>
                       <div *ngIf="message.detail && message.detail.isDetail" class="d-flex justify-content-between align-items-center">
                            <span>{{'MESSAGE.VALIDATION.REQUEST_ERROR_500' | translate}}</span>
                            <button class="btn p-0 mx-3 text-white" (click)="hideme[i] = !hideme[i]">Show details</button>
                       </div>
                    </div>
                    <div *ngIf="message.detail && message.detail.isDetail" class="my-3" [hidden]="!hideme[i]">
                        <code class="text-white">
                           {{message.detail.errorDetail}}
                        </code>
                    </div>
                </ng-container>
            </div>
            <button class="close pl-0" aria-label="Close" type="button" (click)="close(message)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>