import { DetailErrorModel } from './alert-toast.model';

export class AlertToastParams {
    alertToastMessage = '';
    logMessage: any = null;
    translateParams: any;
    displayThroughNavigation = false;
    autoClose = false;
    detail: DetailErrorModel | null = null;

    constructor(data: any = null) {
      if (data) {
        this.alertToastMessage = data.alertToastMessage;
        this.logMessage = data.logMessage;
        this.translateParams = data.translateParams;
        this.displayThroughNavigation = data.displayThroughNavigation;
        this.autoClose = data.autoClose;
        this.detail = data.detail;
      }
    }
  }
