import { Component } from '@angular/core';
import { FooterModel } from 'src/app/model/footer.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public footerInfo = new FooterModel();

  constructor(){
    this.createFooterInfo();
  }

  private createFooterInfo() {
    this.footerInfo = new FooterModel({
      app: environment.appName,
      version: ` - Version ${environment.version}`
    });
  }
}
