export enum MessageLevelModel {
  ERROR,
  WARN,
  INFO,
  SUCCESS
}

export class DetailErrorModel {
  isDetail = false;
  msg = '';
  errorDetail = '';

  constructor(data: any = null) {
    if (data) {
      this.isDetail = data.isDetail;
      this.msg = data.msg;
      this.errorDetail = data.errorDetail;
    }
  }
}
export class AlertToastModel {
  level: MessageLevelModel = MessageLevelModel.ERROR;
  cssClass = '';
  icoCss  = '';
  timeOut  = 0;
  message = '';
  removed = false;
  displayThroughNavigation = false;
  autoClose = false;
  id: string;
  detail: DetailErrorModel = new DetailErrorModel();

  constructor(data: any = null)
  {
    this.id = Date.now().toString();

    if (data != null) {
      this.level = data.level;
      this.message = data.message;
      this.displayThroughNavigation = data.displayThroughNavigation;
      this.autoClose = data.autoClose;
      this.cssClass = data.cssClass;
      this.icoCss = data.icoCss;
      this.timeOut = data.timeOut;
      this.detail = data.detail || new DetailErrorModel();
    }
  }
}
