import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { AlertToastModel, MessageLevelModel } from '../models/alert-toast.model';
import { AlertToastParams } from '../models/alert-toast-params.model';

@Injectable()
export class AlertToastService {
  private messageStream: Subject<AlertToastModel>;
  private toastStatusStream: Subject<any> = new Subject();

  constructor(private translateService: TranslateService) {
    this.messageStream = new Subject();
    this.toastStatusStream = new Subject();
  }

  get messages(): Observable<AlertToastModel> {
    return this.messageStream.asObservable();
  }

  public getToastStatus(): Observable<any>  {
    return this.toastStatusStream.asObservable();
  }

  public close() {
    this.toastStatusStream.next({action: 'close'});
  }

  public post(alertToastMessage: AlertToastModel) {
    this.messageStream.next(alertToastMessage);
  }

  public error(data: AlertToastParams) {
    this.alertToastMessage(MessageLevelModel.ERROR,
                          data.alertToastMessage,
                          data.displayThroughNavigation,
                          data.autoClose,
                          data.translateParams,
                          data.detail);  }

  public warn({alertToastMessage = '', logMessage = null, translateParams = {}, displayThroughNavigation = false, autoClose = false}) {
    this.alertToastMessage(MessageLevelModel.WARN, alertToastMessage, displayThroughNavigation, autoClose, translateParams);
  }

  public info({alertToastMessage = '', logMessage: string = null, translateParams = {}, displayThroughNavigation = false, autoClose = false}) {
    this.alertToastMessage(MessageLevelModel.INFO, alertToastMessage, displayThroughNavigation, autoClose, translateParams);
  }


  public success({alertToastMessage = '', logOrDisplay = true, translateOrClose = true}, translateParams?: object) {
    let display = true;
    let close = true;
    let translate = translateParams;

    if (logOrDisplay !== undefined) {
      if (typeof logOrDisplay === 'string') {
      } else if (typeof logOrDisplay === 'boolean') {
        display = logOrDisplay;
      }
    }

    if (translateOrClose !== undefined) {
      if (typeof translateOrClose === 'boolean') {
        close = translateOrClose;
      } else {
        translate = translateOrClose;
      }
    }

    this.alertToastMessage(MessageLevelModel.SUCCESS, alertToastMessage, display, close, translate);
  }

  private alertToastMessage(level: MessageLevelModel,
                            messageToast: string,
                            displayThroughNavigation: boolean,
                            autoClose: boolean,
                            translateParams = {},
                            detail: any = null) {
    /**
     * .subscribe is used instead of .instant because of an issue that exists
     * when a flash message needs to be thrown during ngOnInit.  If .instant is used,
     * the label will be untranslated.
     */
    this.translateService.get(messageToast, translateParams).subscribe(message => {
      this.post(new AlertToastModel({level, message, displayThroughNavigation, autoClose, detail}));
    });
  }
}
