import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { JwtTokenManagementStore } from '../module-authorization/services/jwt-token-management.store';

@Injectable({
  providedIn: 'root'
})
class PermissionsService {

  constructor(private router: Router, private _tokenStorage: JwtTokenManagementStore) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const status = await this._tokenStorage.getSessionStatus();
    return status;
  }
}

export const OktaGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any => {
  return inject(PermissionsService).canActivate(next, state);
};
