export class UserLoginHistoryModel {
    projectName = '';
    loginTime = '';

    constructor(data: any = null){
        if (data) {
            this.projectName = data.tableau_project;
            this.loginTime = data.last_login;
        }
    }
}
