
export class RouteConstant {
    static readonly APP_CONST_EMPTY = '';
    static readonly LOGIN = 'login';
    static readonly LOGIN_CALLBACK = 'login/callback';
    static readonly SECURE = 'secure';
    static readonly DASHBOARD = 'insight-dashboard';
    static readonly ADMIN = 'admin';
    static readonly USERS = 'users';
    static readonly PROJECTS = 'projects';
}
