import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/internal/operators';
import { UserService } from 'src/app/module-administrator/services/users/user.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
})
export class AccessDeniedComponent implements OnInit {

  public contactEmail = '';
  public contact = '';

  constructor(private translateService: TranslateService, private userService: UserService) {
  }

  ngOnInit() {

    this.translateService.get('APP.ACCESS_DENIED.CONTACT')
      .pipe(take(1))
      .subscribe((text: string) => {
        this.contact = text;
      });
  }

}
