import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { RouteConstant } from './const/insignts-routing.const';
import { NotFoundComponent } from './module-authorization/components/not-found/not-found.component';
import { LoginComponent } from './module-authorization/components/login/login.component';
import { OktaGuard } from './guards/okta.guard';
import { ProjectsResolver } from './module-administrator/resolvers/projects.resolver';

const routes: Routes = [
  {
    path: RouteConstant.APP_CONST_EMPTY,
    component: LoginComponent
  },
  {
    path: RouteConstant.LOGIN_CALLBACK,
    component: OktaCallbackComponent,
  },
  {
    path: RouteConstant.SECURE,
    canActivate: [OktaGuard],
    loadChildren: () => import('./module-authorization/authorization.module').then(m => m.ModuleAuthorizationModule),
    resolve: {
      projectDataList: ProjectsResolver
    }
  },
  {
    path: '**',
    pathMatch: 'full',
    canActivate: [OktaGuard],
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
