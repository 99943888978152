import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/model/user.model';
import { MainDashboardStore } from '../../services/main-dashboard.store';
import { JwtTokenManagementStore } from 'src/app/module-authorization/services/jwt-token-management.store';
import { SpinnerService } from 'src/app/module-shared/services/spinner.service';
import { catchError, take } from 'rxjs/operators';
import { LOADING_DASHBOARD } from 'src/app/const/loading.const';
import { AdminLayoutListenerService } from 'src/app/module-authorization/services/admin-layout-listener.service';
@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit, AfterViewInit {

  public user: UserModel | null = null;
  public dashboardUrl: string | null = null;
  public token: string | undefined = '';
  public insightsTableauId = 'insights-tableau-id';
  public thisViz: any;
  public thisWorbook: any;
  public dashboardName = '';
  public isDashboardLoaded = false;
  public isDownloadDisabled = true;
  public isUserActive = false;

  constructor(private _mainDashboardStore: MainDashboardStore,
              private _jwtTokenManagementStore: JwtTokenManagementStore,
              private _spinnerService: SpinnerService,
              private _adminLayoutListenerService: AdminLayoutListenerService, ) {
    this.token = this._jwtTokenManagementStore.getToken();
  }

  ngOnInit(): void {
    this.createSubscriptions();
  }

  ngAfterViewInit() {
    const viz = document.getElementById(this.insightsTableauId);
    if (viz) {
      viz.addEventListener('firstinteractive', async (event) => {
        this.thisViz = (viz as any);
        this.dashboardName = this.thisViz.workbook.activeSheet.name;
        this.isDashboardLoaded = true;
      });

      viz.addEventListener('markselectionchanged', async (event: any) => {
        this.isDownloadDisabled = false;
      });

      viz.addEventListener('tabswitched', async (event) => {
        this.isDownloadDisabled = true;
      });

     }
   }

  public downloadCrossTab() {
    this.thisViz.displayDialogAsync('export-cross-tab');
  }

  public downloadPdf() {
    this.thisViz.displayDialogAsync('export-pdf');
  }

  public downloadPowerPoint() {
    this.thisViz.displayDialogAsync('export-powerpoint');
  }

  private createSubscriptions() {
    this._adminLayoutListenerService.getIsUserActive().subscribe(item => {
      if (item !== null) {
        this.isUserActive = item;
      }
    });
    this._mainDashboardStore.getDashboardByUser()
        .subscribe(response => {
          this.dashboardUrl = response.URL;
          this._spinnerService.stopLoading(LOADING_DASHBOARD);
        });
  }
}
