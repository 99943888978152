import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainDashboardService } from './main-dashboard.service';
import { DashboardModel } from '../model/dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class MainDashboardStore {
  constructor(private _mainDashboardService: MainDashboardService) { }

  public getDashboardByUser(): Observable<DashboardModel> {
    return this._mainDashboardService.getDashboardByUser();
  }
}
