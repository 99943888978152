<ng-container *ngIf="isUserActive; else noActiveUser">   
    <div class="main-dashboard--container">        
        <tableau-viz   *ngIf="token"
                        toolbar="hidden"
                        id={{insightsTableauId}}
                        src={{dashboardUrl}}
                        token='{{token}}'>
        </tableau-viz>
        
        <div class="row justify-content-end m-3 pb-3" *ngIf="isDashboardLoaded">
            <div class="main-dashboard--download-opt" >
                <ods-dropdown-button btnName="Download" type="navbar" dropdownId="download-dropdown">
                    <ng-container ods-dropdown-button-items>                  
                        <button type="button" class="btn dropdown-item" (click)="downloadCrossTab()">
                            {{'APP.DOWNLOAD.CROSS_TAB' | translate}}
                        </button>
                        <button type="button" class="btn dropdown-item" (click)="downloadPdf()">
                            {{'APP.DOWNLOAD.PDF' | translate}}
                    </button>
                    <button type="button" class="btn dropdown-item" (click)="downloadPowerPoint()">
                        {{'APP.DOWNLOAD.POWERPOINT' | translate}}
                </button>
                    </ng-container>
                </ods-dropdown-button>
            </div>
       </div>
    </div>   
</ng-container>

<ng-template #noActiveUser>
    <app-access-denied></app-access-denied>
</ng-template>
