import { Inject, Injectable } from '@angular/core';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root'
})
export class JwtTokenManagementStore {

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
              private _jwtHelperService: JwtHelperService) { }

  public getUser() {
    return this.oktaAuth.getUser();
  }

  public getToken(): string | undefined {
    return this.oktaAuth.getAccessToken();
  }

  public clearToken() {
    localStorage.clear();
  }

  public removeSession() {
    this.oktaAuth.signOut();
    localStorage.clear();
  }

  public getTokenExpirationDate(): Date | null {
    return this._jwtHelperService.getTokenExpirationDate(this.getToken());
  }

  public getSessionStatus(): Promise<boolean>{
    if (this.oktaAuth && this.oktaAuth.session) {
      return this.oktaAuth.session.exists();
    } else {
      return new Promise((resolve) => resolve(false));
    }
  }
}
