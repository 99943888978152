import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AlertToastLoadingModel } from '../models/alert-toast-loading.model';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private loadingEl: AlertToastLoadingModel[] = [];
  public loadingStatusSubs: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() { }

  public startLoading(idEl: string) {
    const element = this.loadingEl.findIndex(t => t.id === idEl);
    if (element === -1) { this.loadingEl.push({id: idEl, status: true}); }
    this.setLoadingStatus();
  }

  public stopLoading(idEl: string) {
    const indx = this.loadingEl.findIndex(t => t.id === idEl);
    if (indx !== -1) {
      const newLoadingArr = [...this.loadingEl];
      newLoadingArr.splice(indx, indx + 1);
      this.loadingEl = [...newLoadingArr];
    }
    this.setLoadingStatus();
  }

  public stopAllLoading() {
    this.loadingEl = [];
    this.setLoadingStatus();
  }

  public getLoadingStatus() {
    return this.loadingStatusSubs.asObservable();
  }

  public setLoadingStatus() {
    this.loadingStatusSubs.next(this.loadingEl);
  }
}
