import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ServiceConstant } from 'src/app/const/insignts-service-routing.const';
import { DashboardModel } from '../model/dashboard.model';
import { HttpClientInsightsService } from 'src/app/services/http-client-insights.service';

@Injectable({
  providedIn: 'root'
})
export class MainDashboardService {
  private appUrl = `${environment.serviceUrl}`;

  constructor(private _httpClient: HttpClientInsightsService) { }

  public getDashboardByUser(): Observable<DashboardModel> {
    return this._httpClient
              .get(`${this.appUrl}/${ServiceConstant.USER_ADMIN_DASHBOARD}/${ServiceConstant.DASHBOARD}`)
              .pipe(
                map(response => {
                  return new DashboardModel(response);
                })
              );
  }
}
