export class FooterModel {
    app: string | undefined;
    version: string | undefined;
    email: string | undefined;
    emailText: string | undefined;

    constructor(data: any = null)
    {
      if (data != null) {
        this.app = data.app;
        this.version = data.version;
        this.email = data.email;
        this.emailText = data.emailText;
      }
    }
  }
