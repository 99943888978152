import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProjectAllListModel } from '../../models/project-list.model';
import { ServiceConstant } from 'src/app/const/insignts-service-routing.const';
import { HttpClientInsightsService } from 'src/app/services/http-client-insights.service';
import { ProjectModel, ProjectQueryModel, ProjectSearchQueryModel } from '../../models/project-search-list.model';
import { UserModel, UsersSearchQueryModel } from '../../models/user-search-list.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  private appUrl = `${environment.serviceUrl}/${ServiceConstant.USER_ADMIN_PROJECTS}`;

  constructor(private _httpClient: HttpClientInsightsService) { }

  public getAllProjectList(): Observable<ProjectAllListModel> {
    return this._httpClient
          .get(`${this.appUrl}/${ServiceConstant.USER_ADMIN_ALL_PROJECTS}`)
          .pipe(
            map(response => {
              if (response) {
                return new ProjectAllListModel(response);
              }
              return new ProjectAllListModel();
            })
          );
  }

  public getAutoSearchProject(params: string): Observable<string[]> {
    return this._httpClient
          .get(`${this.appUrl}/${ServiceConstant.USER_ADMIN_SEARCH_AUTOCOMPLETE_PROJECT}?project_name=${params}`, null, false)
          .pipe(
            map(dataList => {
              let addressList: string [] = [];
              if (dataList) {
                addressList = dataList.project_list as string[];
              }
              return addressList;
            }));
  }

  public getProjectList(params: ProjectSearchQueryModel): Observable<ProjectModel[]> {
    let paramQuery = (params.project_name.length > 0 || params.active_status.length > 0) ? '?' : '';

    paramQuery += params.active_status.length > 0 ?
                `active_status=${params.active_status}` : '';

    paramQuery += params.project_name.length > 0 ?
                `&` : '';

    paramQuery += params.project_name.length > 0 ?
                `project_name=${params.project_name}` : '';

    const url = `${this.appUrl}/${ServiceConstant.USER_ADMIN_PROJECT_LIST}${paramQuery}`;
    return this._httpClient
          .get(url)
          .pipe(
            map((response: any) => {
              const userList: ProjectModel[] = [];
              if (response && response.project_list) {
                response.project_list.forEach((element: any, index: number) => {
                  userList.push(new ProjectModel({...element, index}));
                });
              }
              return userList;
            })
          );
  }

  public getUserByProject(params: UsersSearchQueryModel): Observable<UserModel[]> {
    const paramQuery = `?project_name=${params.project_name}`;

    const url = `${this.appUrl}/${ServiceConstant.USER_ADMIN_PROJECT_USER}${paramQuery}`;
    return this._httpClient
          .get(url)
          .pipe(
            map((response: any) => {
              const userList: UserModel[] = [];
              if (response && response.user_list) {
                response.user_list.forEach((element: any, index: number) => {
                  userList.push(new UserModel({...element, index}));
                });
              }
              return userList;
            })
          );
  }
  public createNewProject(params: ProjectQueryModel): Observable<any>{
    return this._httpClient.post(`${this.appUrl}/${ServiceConstant.USER_ADMIN_ADD_PROJECT}`, params, 'APP.PROJECT.CREATE_PROJECT');
  }

  public updateProject(params: ProjectQueryModel): Observable<any>{
    return this._httpClient.put(`${this.appUrl}/${ServiceConstant.USER_ADMIN_UPDATE_PROJECT}`, params, 'APP.PROJECT.UPDATE_STATUS_PROJECT');
  }

}
