export class UserInfoModel {
    public status = '';
    public isUserAdmin = false;
    public isUserActive = false;

    constructor(data: any = null) {
        if (data) {
            this.status = data.status;
            this.isUserAdmin = data.admin_flag === 'Y';
            this.isUserActive = data.active_flag === 'Y';
        }
    }
}
