export class UsersSearchQueryModel {
    email = '';
    project_name = '';

    constructor(data: any = null){
        if (data) {
            this.email = data.email || '';
            this.project_name = data.projectName || '';
        }
    }
}

export class UsersQueryModel {
    email_id = '';
    new_email_id = '';
    old_email_id = '';
    user_name = '';
    tableau_project = '';
    active_flag = '';
    admin_flag = '';

    constructor(data: any = null){
        if (data) {
            this.email_id = data.email;
            this.new_email_id = data.email;
            this.old_email_id = data.oldEmail;
            this.user_name = data.userName;
            this.tableau_project = data.projectName.name ? data.projectName.name : data.projectName;
            this.active_flag = data.isActive ? 'Y' : 'N';
            this.admin_flag = data.isAdmin ? 'Y' : 'N';
        }
    }
}

export class UserModel {
    email = '';
    userName = '';
    oldEmail = '';
    uniqId: string | null = null;
    projectName = '';
    isActive = false;
    isAdmin = false;
    lastLoginTime = '';

    constructor(data: any = null){
        if (data) {
            this.uniqId = data.index + '_active';
            this.email = data.email_id;
            this.userName = data.user_name;
            this.oldEmail = data.email_id;
            this.projectName = data.tableau_project;
            this.isActive = data.active_flag === 'Y';
            this.isAdmin = data.admin_flag === 'Y';
            this.lastLoginTime = data.last_login || '';
        }
    }
}
